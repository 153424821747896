import gql from "graphql-tag";
import { AllFragments } from "./fragments";
import { AllPdpFragments } from "./stack_fragments";

export const ProductQuery = gql`
  ${AllFragments}
  ${AllPdpFragments}
  query ($productSlug: String!, $locale: String!) {
    products(where: { slug: $productSlug }) {
      ...ProductFields
      merchandisingModal {
        id
      }
      selectors {
        title
        selectorType
        useProductVariantsAsSelectors
        productShapes {
          label
          text
          priority
          media {
            url
          }
        }
        productFabrics {
          label
          text
          priority
          colorHex
          media {
            url
          }
        }
        productFrames {
          label
          text
          priority
          media {
            url
          }
        }
        productOptions {
          label
          text
          priority
          media {
            url
          }
        }
        productOption2s {
          label
          text
          priority
          media {
            url
          }
        }
        cta {
          ...CTAFields
        }
      }
      addOnProducts {
        id
        title
        selectorType
        cta {
          ...CTAFields
        }
        products {
          enable
          title
          currency {
            name
            code
            symbol
          }
          variants {
            id
            enable
            remoteId
            price
            title
            slug
            outOfStock
            compareAtPrice
            salePriceText
            primaryColorHex
            photos {
              height
              width
              url
              alternativeText
              caption
              provider_metadata
            }
            crossMerchMedia {
              height
              width
              url
              alternativeText
              caption
              provider_metadata
            }
            selectorImage {
              height
              width
              url
            }
            selectorText
            productShape {
              label
            }
            productFabric {
              label
            }
            productFrame {
              label
            }
          }
        }
      }
      stackPage {
        stack {
          __typename
          ... on ComponentStackSeo {
            ...ComponentStackSeoFields
          }
          ... on ComponentStackPressLogos {
            ...ComponentStackPressLogosFields
          }
          ... on ComponentStackAffirmSection {
            id
            included
            product {
              title
            }
          }
          ... on ComponentStackTwoColumnDescription {
            id
            primary
            invert
            body
          }
          ... on ComponentStackOkendoReview {
            id
            okendoReview {
              collectionId
              productId
            }
          }
          ... on ComponentStackShowroomsFinder {
            enable
          }
          ... on ComponentStackMasonryHero {
            id
            title
            pretitle
            pretitleModalText
            pretitleModalMedia {
              url
              alternativeText
            }
            description
            buttonCta
            buttonCtaLink
            videoCta
            videoCtaLink
            galleryItems {
              media {
                url
                alternativeText
              }
            }
          }
          ... on ComponentStackInspirationGallery {
            id
            title
            subtitle
            galleryItems {
              inspirationObject {
                coverPhoto {
                  url
                  height
                  width
                  alternativeText
                }
                caption
                customerName
                customerLabel
                customerLocation
                visitableShowroom
                ctaPrimary
                ctaPrimaryLink
                ctaSecondary
                ctaSecondaryLink
                ctaPrimaryTooltip
                ctaSecondaryTooltip
                products {
                  displayVariantSlug
                  product {
                    title
                    slug
                    currency {
                      name
                      code
                      symbol
                    }
                    variants {
                      slug
                      title
                      price
                      photos {
                        url
                        alternativeText
                      }
                    }
                  }
                  customProduct {
                    title
                    price
                    slug
                    photo {
                      url
                      alternativeText
                    }
                  }
                }
              }
            }
          }
          ... on ComponentStackFaqSection {
            id
            faqs {
              id
              question
              answer
              priority
            }
          }
          ... on ComponentStackLeverJobPositionsBlock {
            id
            primary
            secondary
            tertiary
          }
          ... on ComponentStackMediaGallery {
            primary
            secondary
            gridImagesNumber
            medias: media {
              url
              alternativeText
              caption
              height
              width
              name
            }
            sharedGalleries {
              images {
                url
                alternativeText
                caption
                height
                width
                name
              }
            }
          }
          ... on ComponentStackPromotedCatalogBlock {
            ...ComponentStackPromotedCatalogBlockFields
          }
          ... on ComponentStackFeaturedParagraph {
            ...ComponentStackFeaturedParagraphFields
          }
          ... on ComponentStackPromotedTabbedFeature {
            ...ComponentStackPromotedTabbedFeatureFields
          }
          ... on ComponentStackTwoColumnsContentBlock {
            ...ComponentStackTwoColumnsContentBlockFields
          }
          ... on ComponentStackSingleAccordion {
            id
            title
            content
          }
          ... on ComponentStackTabSelector {
            id
            addToCardButton
            ctas {
              text
              location
              event
            }
          }
          ... on ComponentStackMediaCarousel {
            ...ComponentStackMediaCarouselFields
          }
          ... on ComponentStackMultiColumnContentBlock {
            ...ComponentStackMultiColumnContentBlockFields
          }
          ... on ComponentStackStaticTestimonialBlock {
            ...ComponentStackStaticTestimonialBlockFields
          }
          ... on ComponentStackStickyBar {
            ...ComponentStackStickyBarFields
          }
          ... on ComponentStackHero {
            ...ComponentStackHeroFields
          }
          ... on ComponentStackAccordion {
            ...ComponentStackAccordionFields
          }
          ... on ComponentStackTextBlock {
            ...ComponentStackTextBlockFields
          }
          ... on ComponentStackFireworkVideos {
            ...ComponentStackFireworkVideosFields
          }
          ... on ComponentStackMerchandisingBlock {
            ...ComponentStackMerchandisingBlockFields
          }
          ... on ComponentStackSpacer {
            ...ComponentStackSpacerFields
          }
          ... on ComponentStackCtas {
            ...ComponentStackCtasFields
          }
          ... on ComponentStackContactSection {
            ...ComponentStackContactSectionFields
          }
          ... on ComponentStackTrustSignalContentBlock {
            ...ComponentStackTrustSignalContentBlockFields
          }
          ... on ComponentStackCardScrollBlock {
            ...ComponentStackCardScrollBlockFields
          }
          ... on ComponentStackProductPimDimensions {
            ...ComponentStackProductPimDimensionsFields
          }
          ... on ComponentStackSpecificationTabs {
            ...ComponentStackSpecificationTabsFields
          }
        }
      }
      stackComponents {
        __typename
        ... on ComponentStackProductAccordion {
          __typename
          id
          items {
            title
            content
            entryPosition
            enable
            productAccordion {
              title
              content
              entryPosition
              enable
            }
          }
        }
        ... on ComponentStackSeo {
          ...ComponentStackSeoFields
        }
        ... on ComponentStackPressLogos {
          ...ComponentStackPressLogosFields
        }
        ... on ComponentStackAffirmSection {
          id
          included
          product {
            title
          }
        }
        ... on ComponentStackTwoColumnDescription {
          id
          primary
          invert
          body
        }
        ... on ComponentStackOkendoReview {
          id
          okendoReview {
            collectionId
            productId
          }
        }
        ... on ComponentStackShowroomsFinder {
          enable
        }
        ... on ComponentStackMasonryHero {
          id
          title
          pretitle
          pretitleModalText
          pretitleModalMedia {
            url
            alternativeText
          }
          description
          buttonCta
          buttonCtaLink
          videoCta
          videoCtaLink
          galleryItems {
            media {
              url
              alternativeText
            }
          }
        }
        ... on ComponentStackInspirationGallery {
          id
          title
          subtitle
          galleryItems {
            inspirationObject {
              coverPhoto {
                url
                height
                width
                alternativeText
              }
              caption
              customerName
              customerLabel
              customerLocation
              visitableShowroom
              ctaPrimary
              ctaPrimaryLink
              ctaSecondary
              ctaSecondaryLink
              ctaPrimaryTooltip
              ctaSecondaryTooltip
              products {
                displayVariantSlug
                product {
                  title
                  slug
                  variants {
                    slug
                    title
                    price
                    photos {
                      url
                      alternativeText
                    }
                  }
                }
                customProduct {
                  title
                  price
                  slug
                  photo {
                    url
                    alternativeText
                  }
                }
              }
            }
          }
        }
        ... on ComponentStackFaqSection {
          id
          faqs {
            id
            question
            answer
            priority
          }
        }
        ... on ComponentStackLeverJobPositionsBlock {
          id
          primary
          secondary
          tertiary
        }
        ... on ComponentStackMediaGallery {
          primary
          secondary
          gridImagesNumber
          medias: media {
            url
            alternativeText
            caption
            height
            width
            name
          }
          sharedGalleries {
            images {
              url
              alternativeText
              caption
              height
              width
              name
            }
          }
        }
        ... on ComponentStackPromotedCatalogBlock {
          ...ComponentStackPromotedCatalogBlockFields
        }
        ... on ComponentStackFeaturedParagraph {
          ...ComponentStackFeaturedParagraphFields
        }
        ... on ComponentStackPromotedTabbedFeature {
          ...ComponentStackPromotedTabbedFeatureFields
        }
        ... on ComponentStackTwoColumnsContentBlock {
          ...ComponentStackTwoColumnsContentBlockFields
        }
        ... on ComponentStackSingleAccordion {
          id
          title
          content
        }
        ... on ComponentStackTabSelector {
          id
          addToCardButton
          ctas {
            text
            location
            event
          }
        }
        ... on ComponentStackMediaCarousel {
          ...ComponentStackMediaCarouselFields
        }
        ... on ComponentStackMultiColumnContentBlock {
          ...ComponentStackMultiColumnContentBlockFields
        }
        ... on ComponentStackStaticTestimonialBlock {
          ...ComponentStackStaticTestimonialBlockFields
        }
        ... on ComponentStackStickyBar {
          ...ComponentStackStickyBarFields
        }
        ... on ComponentStackHero {
          ...ComponentStackHeroFields
        }
        ... on ComponentStackAccordion {
          ...ComponentStackAccordionFields
        }
        ... on ComponentStackTextBlock {
          ...ComponentStackTextBlockFields
        }
        ... on ComponentStackFireworkVideos {
          ...ComponentStackFireworkVideosFields
        }
        ... on ComponentStackMerchandisingBlock {
          ...ComponentStackMerchandisingBlockFields
        }
        ... on ComponentStackSpacer {
          ...ComponentStackSpacerFields
        }
        ... on ComponentStackCtas {
          ...ComponentStackCtasFields
        }
        ... on ComponentStackContactSection {
          ...ComponentStackContactSectionFields
        }
        ... on ComponentStackTrustSignalContentBlock {
          ...ComponentStackTrustSignalContentBlockFields
        }
        ... on ComponentStackCardScrollBlock {
          ...ComponentStackCardScrollBlockFields
        }
      }
    }
    outOfStockNotificationRequest(locale: $locale) {
      locale
      primary
      klaviyoList
      form_confirmation {
        primary
        body
      }
    }
  }
`;
